import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["logoWrapper", "logoSimple", "logoPayoff"];

  connect() {
    this.isTransitioning = false;

    this.scrollHandler = this.scroll.bind(this);
    this.scrollHandler();

    this.element.addEventListener("transitionstart", this.transitionStartHandler.bind(this));
    this.element.addEventListener("transitionend", this.transitionEndHandler.bind(this));

    window.addEventListener("scroll", this.scrollHandler);
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  transitionStartHandler() {
    this.isTransitioning = true;
  }

  transitionEndHandler() {
    this.isTransitioning = false;
  }

  scroll() {
    if (!this.isTransitioning) {
      if (this.element.offsetHeight - window.scrollY < 110) {
        this.element.style.height = "120px";
        this.element.style.backgroundColor = "rgba(255, 255, 255, 0.9)";
        this.logoWrapperTarget.style.height = "40px";
        this.logoWrapperTarget.style.width = "90px";
        this.logoSimpleTarget.style.opacity = "1";
        this.logoSimpleTarget.style.top = "0px";
        this.logoPayoffTarget.style.opacity = "0";
        this.logoPayoffTarget.style.top = "0px";
      } else {
        this.element.style.height = "210px";
        this.element.style.backgroundColor = "rgba(255, 255, 255, 0)";
        this.logoWrapperTarget.style.height = "100px";
        this.logoWrapperTarget.style.width = "150px";
        this.logoSimpleTarget.style.opacity = "0";
        this.logoSimpleTarget.style.top = "40px";
        this.logoPayoffTarget.style.opacity = "1";
        this.logoPayoffTarget.style.top = "40px";
      }
    }
  }
}
