import autofocus from "./autofocus";
import autosubmit from "./autosubmit";
import calculator from "./calculator";
import chart from "./chart";
import checked from "./checked";
import clone from "./clone";
import datalayer from "./datalayer";
import datepicker from "./datepicker";
import disable_from from "./disable_from";
import expand from "./expand";
import meta from "./meta";
import modals from "./modals";
import packaging_options from "./packaging_options";
import popovers from "./popovers";
import radio_group from "./radio_group";
import redirect from "./redirect";
import scroll_bottom from "./scroll_bottom";
import select from "./select";
import select2 from "./select2";
import spin from "./spin";
import submit_title from "./submit_title";
import tables from "./tables";
import toasts from "./toasts";
import toggle from "./toggle";
import tooltips from "./tooltips";
import total_calculator from "./total_calculator";
import validations from "./validations";
import valuation from "./valuation";

class Jero {
  start() {
    document.addEventListener("turbo:load", () => this.attach(true));
    document.addEventListener("turbo:render", () => this.attach(true));
    document.addEventListener("turbo:frame-load", () => this.attach());
    document.addEventListener("turbo:frame-render", () => this.attach());
  }

  attach(initial = false) {
    autofocus(initial);
    autosubmit();
    calculator();
    chart();
    checked();
    clone();
    datalayer();
    datepicker();
    disable_from();
    expand();
    meta();
    modals();
    packaging_options();
    popovers();
    radio_group();
    redirect();
    select();
    select2();
    spin();
    submit_title();
    tables();
    toasts();
    toggle();
    tooltips();
    total_calculator();
    validations();
    valuation();

    scroll_bottom();
  }
}

if (!window.Jero) {
  window.Jero = new Jero();
}

export default window.Jero;
