import { findAll, isUnattached, setAttached } from "./dom";

const handler = (event) => {
  const url = event.target.closest(".gk-packaging-options").dataset.url;
  const target = event.target.closest(".gk-packaging-options").querySelector(".gk-packaging-options-target");

  fetch(
    url +
    "?" +
    new URLSearchParams({ wine_id: event.target.value })
  ).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        const empty = document.createElement("option");
        const options = data.map((value) => {
          const option = document.createElement("option");
          option.value = value;
          option.text = value;
          return option;
        });
        target.replaceChildren(...[empty, ...options]);
      });
    }
  });
};

export default () =>
  findAll(document, ".gk-packaging-options")
    .filter((element) => isUnattached("packaging_options", element))
    .map((element) => setAttached("packaging_options", element))
    .forEach((element) => {
      element.querySelectorAll(".gk-packaging-options-source").forEach((source) => {
        source.addEventListener("input", handler);
      });
    });
